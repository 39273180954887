@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

@font-face {
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
    font-weight: normal;
    font-style: normal;
}

* {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
}
body{
    background: #242424;
}
header {
    height: 74px;
    background: #000000;
    display: flex;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
}
div{
    word-break: keep-all;
    text-align: center;
}
.header-title {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.04em;
    color: #FFFFFF;
    position: relative;
    min-width: 285px;
    width: 285px;
    padding: 22px 24px;
    text-decoration: none;
}
.header-tab-container{
    position: relative;
    width: 100%;
    display: flex;
    color: #646464;
    justify-content: center;
    align-items: center;
}
.tab-position-guider {
    width: 100%;
    max-width: 1080px;
    height: 100%;
    margin-right: 200px;
    display: flex;
    justify-content: space-between;
}
.tab{
    margin-right: 56px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: relative;
}
.tab a{
    font-style: normal;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.04em;
    text-decoration: none;
    color: silver;
    transition: 0.3s;
}
.under-bar{
    height: 4px;
    background: black;
    position: absolute;
    width: 100%;
    bottom: 0;
}

/* search bar */
.search-form{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.search-form label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 200px;
    width: 100%;
    height: 100%;
}
.search-button{
    position: absolute;
    width: 0px;
    border: none;
    background: none;
    padding: 0 0;
    bottom: 24px;
}
.search-button img {
    margin-left: 27px;
    cursor: pointer;
}
input.search-input {
    width: 500px;
    border: none;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.2);
    bottom: 12px;
    color: #A0A0A0;
    padding: 10px 64px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.main-login-container {
    position: relative;
    /*width: 70%;*/
    display: flex;
    /*justify-content: flex-start;*/
    align-items: center;
    /*margin: auto;*/
}
.main-login-button {
    position: relative;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: white;
    background: rgba(255, 255, 255, 0.2);
    padding: 10px 64px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-id {
    font-style: normal;
    font-size: 17px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.04em;
    text-decoration: none;
    color: silver;
    margin-left: 45px;
    transition: 0.3s;
    position: relative;
    margin-right: 15px;
}

.header-logo {
    position: relative;
    height: 100%;
}
.header-logo img {
    height: 100%;
}

/* 전체 화면 */
.main {
    min-width: 360px;
    /* width: 1080px; */
    height: 100%;
    /* margin: auto; */
    max-width: 1080px;
    margin: 72px auto;
    color: silver;
}

form.main-form {
    /*padding: 30px 0;*/
}
/* 홈의 랜덤 메타데이터 */
.meta-container{
    display: flex;
    flex-direction: column;
}
.movie-detail-title,
.metadata-based-title,
.cf-based-title,
.meta-container-title {
    text-align: left;
    padding: 18px 0;
    font-size: 20px;
    color: white;
}

details {
    margin-top: 30px;
    margin-bottom: 10px;
    /*background: rgba(255, 255, 255, 0.2);*/
    /*border-radius: 8px;*/
}
details summary {
    text-align: left;
    color: white;
    font-size: 18px;
}
.cf-user-contents-container {
    padding: 15px 6px;
}
.meta-detail-content-container{
    display: flex;
    flex-direction: column;
}
.detail-meta-value-container {
    background: #000000;
    color: #F0F0F0;
    border-radius: 50px;
    padding: 12px 24px;
    gap: 10px;
    margin: 0.3rem;
    border: 1px solid #F0F0F0;
    cursor: pointer;
    text-align: center;
    min-width: 40px;
}
.button-container{
    display: flex;
    justify-content: flex-end;
}
/* 컨텐츠의 헤더 */

.main-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div.select_movie_items_title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.04em;
    text-align: start;
    color: #A0A0A0;
    padding: 31px 0 24px 0;
}
.user-ontology-list,
.user-ontology-movie-list {
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 30px;
    letter-spacing: -0.04em;
    text-align: start;
    color: #A0A0A0;
    padding: 20px 0 10px 0;
}
.page-container{
    display: flex;
    align-items: center;
}
.page-container div{
    margin: 0 6px;
    transition: 0.3s;
}
.page-button{
    cursor: pointer;
}
.page-button:hover,.tab a:hover,
.serendipity-toggle:hover,
.cf-toggle:hover,
.toggle:hover {
    color: white;
}
#result {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.movies-button-container, .attribute-button-container, .signup-button-container {
    display: flex;
    justify-content: flex-end;
    padding: 30px 20px;
    /*width: 1080px;*/
}

/* serendipity */
/* 임시 */
.toggle-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.serendipity-toggle,.cf-toggle, .toggle {
    transform: rotate(90deg);
    width: 30px;
    height: 30px;
    transition: 1s;
    cursor: pointer;
    font-size: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: silver;
}
.serendipity-toggle.reveal,.cf-toggle.reveal, .toggle.reveal {
    transform: rotate(270deg);
}
.curation-container, .collaborative-container{
    color: #FFFFFF;
    max-width: 1080px;
}
/*
.serendipity-container, .cf-container{
    display: none;
}
.serendipity-container.reveal, .cf-container.reveal{
    display: flex;
    flex-direction: column;
    animation: fade-in 1s;
    animation-fill-mode: forwards;
}
*/
.serendipity-title{
    text-align: left;
    padding: 18px 0;
    font-size: 20px;
}
.serendipity-subtitle {
    text-align: start;
    color: #A0A0A0;
    line-height: 2rem;
}
@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;

    }
    to {
        opacity: 0;
    }
}
.weather-title{
    text-align: left;
    padding: 12px;
}
.weather-contents-container{
    width: 1080px;
    display: flex;
    justify-content: space-around;
}

.weather-contents-container div {
    width: 202px;
    height: 360px;
    background: black;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.movie-attribute-view-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 24px 12px;
}
.movie-attribute-view-container-title{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #FFFFFF;
}
.movie-attribute-view-container-movie-id{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #A0A0A0;
}

.movie-attribute-view-container .submit-button {
    position: absolute;
    border: none;
    border-radius: 50%;
    min-width: 32px;
    width: 32px;
    padding: 0;
    cursor: pointer;
    font-size: 0.9rem;
    min-height: 22px;
    height: 32px;
    right: 8px;
    top: 8px;
    background: black;
    opacity: 0.7;
}

.submit-button {
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 80%), 0px 0px 24px rgb(0 0 0 / 90%);
    border-radius: 16px;
    background: #AEFF00;
    padding: 24px 32px;
    width: 139px;
    height: 64px;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.04em;
}
.recommend-button{
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 80%), 0px 0px 24px rgb(0 0 0 / 90%);
    border-radius: 16px;
    background: #AEFF00;
    padding: 24px 32px;
    width: 139px;
    height: 64px;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.04em;
}
button.submit-button img {
    width: 18.67px;
    height: 18.67px;
    min-width: 18px;
    max-width: 18.67px;
    max-height: 18.67px;
    object-fit: none;
}
.movie-list-item,
.metadata-movie-list-item {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    /*min-width: 1080px;*/
}
.metadata-movie-container{
    margin-bottom: 24px;
    margin-right: 12.5px;
}
.movie-checkbox{
    /*margin: 2vh 1.2vw;*/
    margin-bottom: 15px;
    margin-right: 12.5px;
    max-width: 206px;
    cursor: pointer;
}
.movie-checkbox:nth-child(5n) {
    margin-right: 0;
}
.movie-checkbox input {
    display: none;
}
.movie-list-item .movie-container,
.metadata-movie-list-item .movie-container,
.selected-movie-container .movie-container {
    padding: 0;
    border-radius: 8px;
    /*cursor: pointer;*/
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: #121212;
    /*border: 2px solid #121212;*/
}
.movie-overlay{
    display: none;
}
.movie-overlay.movie-clicked {
    display: block;
    position: absolute;
    width: 100%;
    background: rgba(0,0,0,0.7)url(https://banana.o2o.kr/img2/poc02/check.svg) center no-repeat;
    height: 100%;
    border: 2px solid #AEFF00;
    border-radius: 8px;
}
.attribute-container {
    padding: 2vh 0 0 0;
}
.movie-list-item img,
.metadata-movie-list-item img{
    min-width: 202px;
    max-width: 202px;
    height: 295px;
    max-height: 295px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
    /*cursor: pointer;*/
}
.selected-movie-container img {
    min-width: 202px;
    max-width: 202px;
    height: 295px;
    max-height: 295px;
    object-fit: cover;
    border-radius: inherit;
    cursor: pointer;
}
.movie-id{
    display: none;
}


.nan-movie-name {
    font-size: 1em;
    font-weight: 500;
    padding: 0 1vw;
    color: black;
}

.guide-nan-text {
    position: relative;
    font-size: 0.7em;
    font-weight: 400;
    color: gray;
}

.selected-movies-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1080px;
}
.movie-similarity-container{
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.04em;
    color: #A0A0A0;
    display: flex;
    flex-direction: column;
    width: 1080px;
    min-width: 680px;
    max-width: 1080px;
    background: black;
    height: 116px;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}
.similarity-result{
    display: flex;
    justify-content: center;
    align-items: center;
}
.similarity-result div:nth-child(-n+2) {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #F0F0F0;
}
.similarity-result div:last-child {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #AEFF00;
}
.selected-movies-guide {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.04em;
    color: #DEDEDE;
}
.content-container{
    display: flex;
    justify-content: center;
}
.selected-movie-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 47%;
}

.ontology-container,
.genre-container,
.actor-container,
.director-container,
.search-container  {
    align-items: center;
    display: flex;
    margin-bottom: 0.4rem;
}

.ontology-checkbox input,
.genre-checkbox input,
.actor-checkbox input,
.director-checkbox input {
    display: none;
}

.ontology-checkbox-container,
.genre-checkbox-container,
.actor-checkbox-container,
.director-checkbox-container,
.user-ontology-container {
    display: flex;
    flex-wrap: wrap;
}


.ontology-value-container,
.genre-value-container,
.actor-value-container,
.director-value-container {
    background: #c3d0fb;
    color: #2f447d;
    border-radius: 8px;
    padding: 3px 5px;
    margin: 0.2rem 0.3rem;
    border: solid 1px #c3d0fb;
    cursor: pointer;
    text-align: center;
    min-width: 40px;
}

.detail-ontology-value-container,
.detail-genre-value-container,
.detail-actor-value-container,
.detail-director-value-container,
.metadata-title {
    background: #000000;
    color: #F0F0F0;
    border-radius: 50px;
    padding: 6px 12px;
    gap: 10px;
    margin: 0.3rem;
    border: 1px solid #F0F0F0;
    cursor: pointer;
    text-align: center;
    min-width: 40px;
}

.attribute-click {
    border: solid 1px #AEFF00;
    color: #AEFF00;
    background: rgba(174, 255, 0, 0.1);
}

.attribute-title {
    font-weight: 500;
    margin-right: 2vw;
    min-width: 32px;
    color: #DEDEDE;
}
/*
.movie-list-item .movie-container:hover {
    transform: scale(1.1);
    border-radius: 0.8rem;
    z-index: 1;
}

.movie-list-item img:hover {
    opacity: 1;
}
*/

/*
    final recommendation block
*/
.recommend-container {
    border-top: 1px solid;
    color: white;
}
.recommend-item-container {
    display: flex;
    align-items: flex-start;
    margin: 3vh 0;
    min-height: 180px;
    flex-direction: column;
}
.attribute-name-container {
    margin-bottom: 24px;
    color: #AEFF00;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    display: flex;
}
.recommend-attribute-container {
    display: flex;
    flex-wrap: wrap;
}
.no-result-guide-container {
    font-size: 2rem;
    padding: 0 2vw;
    word-break: keep-all;
}
.recommend-content-container{
    min-width: 110px;
    width: 202px;
    min-height: 180px;
    border-radius: 0.5rem;
    transition: 0.3s;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-bottom: 24px;
    margin-right: 12.5px;
    max-width: 202px;
    background: #121212;
}
.recommend-content-container img {
    width: -webkit-fill-available;
    max-width: 202px;
    max-height: 295px;
    object-fit: cover;
    border-radius: 0.5rem 0.5rem 0 0;
    cursor: pointer;
}
.item-footer-container {
    min-height: 80px;
    height: fit-content;
    max-height: fit-content;
    text-align: center;
    word-break: break-word;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    width: 100%;
    flex-direction: column;
}
.movie-name {
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1.6rem;
}

/*
loading
*/
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;
    z-index: 98;
}

.loader {
    position: fixed;
    /*top: calc(50% - 32px);*/
    top: 40vh;
    left: calc(50% - 64px);
    width: 13vw;
    height: 13vw;
    border-radius: 50%;
    perspective: 1600px;
}

.inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.inner.one {
    left: 0%;
    top: 0%;
    animation: rotate-one 1s linear infinite;
    border-bottom: 5px solid #0096FF;
}

.inner.two {
    right: 0%;
    top: 0%;
    animation: rotate-two 1s linear infinite;
    border-right: 5px solid #0096FF;
}

.inner.three {
    right: 0%;
    bottom: 0%;
    animation: rotate-three 1s linear infinite;
    border-top: 5px solid #0096FF;
}

@keyframes rotate-one {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes rotate-two {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes rotate-three {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}

.loader2 {
    font-size: 48px;
    /*font-family: Arial, Helvetica, sans-serif;*/
    font-weight: bold;
    top: 40vh;
    left: calc(50% - 88px);
    display: inline-block;
    color: #AEFF00;
    letter-spacing: 2px;
    position: fixed;
    box-sizing: border-box;
    z-index: 99;
}
.loader2::after {
    content: 'Loading';
    position: absolute;
    left: 0;
    color: #FFF;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    animation: animloader 3s linear infinite;
}

@keyframes animloader {
    0% {
        height: 100%;
    }
    100% {
        height: 0%;
    }
}


/*

!* loading *!
.square{
    position: fixed;
    display: none;
    top: calc(50% - 40px);
    top: -webkit-calc(50% - 40px);
    top: -moz-calc(50% - 40px);
    !* border:0; *!
    !* width:80px; *!
    !* padding:0px; *!
    !* margin-top: 90px; *!
    margin-left: calc(50% - 40px);
    margin-left: -webkit-calc(50% - 40px);
    margin-left: -moz-calc(50% - 40px);
    background:none;
}

.spin {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    border: dashed 5px gray;
    -webkit-animation-name: spin;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
}

@-webkit-keyframes spin {
    from   {  -webkit-transform: rotate(0deg); }
    to   {  -webkit-transform: rotate(360deg); }
}
.loading-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background: gray;
    top: 0;
    opacity: 0.5;
    display: none;
}*/


