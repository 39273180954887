/*
 movie detail block
*/
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

@font-face {
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
    font-weight: normal;
    font-style: normal;
}

* {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
}

.main{
    min-width: 360px;
    height: 100%;
    max-width: 1080px;
    margin: 72px auto;
    color: silver;
}

.meta-container{
    display: flex;
    flex-direction: column;
}

.meta-detail-content-container {
    display: flex;
    flex-direction: column;
}
.meta-container-title {
    text-align: left;
    padding: 18px 0;
    font-size: 20px;
    color: white;
}

.recommend-button {
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 80%), 0px 0px 24px rgb(0 0 0 / 90%);
    border-radius: 16px;
    background: #AEFF00;
    padding: 24px 32px;
    width: 139px;
    height: 64px;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.04em;
}

.ontology-checkbox.selected .detail-meta-value-container {
    background-color: green;
    color: white;
}



/* 추가 */
.movie-detail-container {
    position: relative;
    min-width: 360px;
    max-width: 1080px;
    margin: auto;
    height: fit-content;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 72px;
    color: #FFFFFF;
}
.detail-thumbnail-container {
    display: flex;
    align-items: center;
    max-width: 1080px;
    margin-top: 32px
}
.movie-detail-content-container {
    display: flex;
    flex-direction: column;
    height: fit-content;
    background: black;
    width: 816px;
    margin-left: 24px;
    max-width: 816px;
    border-radius: 8px;
    padding: 24px;
    position: relative;
}

.detail-attribute-container {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
}
.detail-attribute-title {
    color: #A0A0A0;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    letter-spacing: -0.04em;
}
.detail-ontology-container, .detail-genre-container, .detail-actor-container, .detail-director-container {
    margin-bottom: 12px;
}
.ontology-checkbox input, .genre-checkbox input, .actor-checkbox input, .director-checkbox input {
    display: none;
}

.ontology-checkbox-container,
.genre-checkbox-container,
.actor-checkbox-container,
.director-checkbox-container {
    margin-top: 4px;
}

.close-button {
    border: none;
    box-shadow: 1px 1px 6px grey;
    border-radius: 9px;
    padding: 7px 12px;
    cursor: pointer;
    font-size: 1rem;
    position: fixed;
    right: 30px;
    top: 20px;
}

.movie-detail-title {
    text-align: center;
    font-size: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.04em;
    color: #F0F0F0;
}

.movie-detail-description {
    /*margin: auto;*/
    margin-left: 50px;
    margin-right: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #A0A0A0;
}

.detail-submit-button {
    position: relative;
    /*bottom: 24px;*/
    /*right: 24px;*/
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 80%), 0px 0px 24px rgb(0 0 0 / 90%);
    background: #AEFF00;
    padding: 24px 32px;
    width: 139px;
    height: 64px;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.04em;
}
.rating-button {
    /*position: absolute;*/
    right: 24px;
    bottom: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 80%), 0px 0px 24px rgb(0 0 0 / 90%);
    background: #AEFF00;
    padding: 24px 32px;
    width: 139px;
    height: 64px;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.04em;
    border-radius: 16px;
}
.rating-container {
    display: flex;
    align-items: center;
    max-width: 1080px;
    margin-top: 32px;
    height: 100px;
}

.naver-search {
    text-align: center;
    cursor: pointer;
    width: 240px;
    padding: 10px;
}
img.naver-search-image {
    height: 64px;
    border-radius: 16px;
}
.star-container {
    /*flex-direction: column;*/
    background: black;
    width: 816px;
    margin-left: 24px;
    max-width: 816px;
    border-radius: 8px;
    padding: 24px;
    position: relative;
}
.star-container-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.star-rating {
    display: flex;
    flex-direction: row-reverse;
    font-size: 2.25rem;
    line-height: 2.5rem;
    justify-content: space-around;
    padding: 0 0.2em;
    text-align: center;
    /*width: 80%;*/
    width: 5em;
    position: relative;
}
.rating-db-button{
    border-radius: 12px;
    position: relative;
    bottom: 0;
    right: 0;
}

.star-rating input {
    display: none;
}

.star-rating label {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.2); /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 2.3px;
    -webkit-text-stroke-color: #AEFF00;
    cursor: pointer;
}

.star-rating :checked ~ label {
    -webkit-text-fill-color: #AEFF00;
}

.star-rating label:hover,
.star-rating label:hover ~ label {
    -webkit-text-fill-color: #AEFF00;
}

.detail-img-container {
    min-width: 70px;
    width: 240px;
    max-width: 240px;
    min-height: 180px;
    height: 344px;
    max-height: 344px;
    /* margin: 4vh 0; */
    /* padding: 0; */
    /* border: none; */
    border-radius: 8px;
    /* box-shadow: 0px 0px 23px grey; */
    cursor: pointer;
    /* transition: 0.3s; */
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    align-items: center;
}
.detail-img-container img{
    width: 240px;
    max-width: 240px;
    height: 344px;
    max-height: 344px;
    object-fit: cover;
    border-radius: 8px;
    cursor: auto;
}
.attribute-description {
    text-align: left;
    margin-top: 56px;
    width: 100%;
}
.attribute-description div:first-child{
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
    letter-spacing: -0.04em;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 8px;
}
.attribute-description div:nth-child(2){
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: #DEDEDE;
    text-align: left;
    margin-bottom: 4px;
}
.attribute-description div:nth-child(3){
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.04em;
    color: #A0A0A0;
    text-align: left;
}
.table-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: flex-start;
}
.detail-table-container{
    border-radius: 12px;
}
.detail-table {
    border-collapse: collapse;
    margin: 30px 0;
}
.detail-table tbody tr td:first-child,
.detail-table tbody tr td:last-child{
    color: #A0A0A0;
}
table.detail-table thead{
    background: rgba(255, 255, 255, 0.1);
}
table.detail-table th{
    padding: 10px;
    min-width: 40px;
    text-align: center;
}
table thead tr th:first-child {
    border-radius: 8px 0 0 0;
}
table thead tr th:last-child {
    border-radius: 0 8px 0 0;
}
tbody{
    background: #121212;
}
table tbody td {
    border-top: 0.5px solid #4D4D4D;
    padding: 10px;
    min-width: 40px;
    text-align: left;
}
tbody tr:last-child td:first-child {
    border-radius: 0 0 0 8px;
}
tbody tr:last-child td:last-child {
    border-radius: 0 0 8px 0;
}
.detail-search-value-container {
    background: #c3d0fb;
    color: #2f447d;
    border-radius: 8px;
    padding: 3px 5px;
    margin-left: 20px;
    /* border: solid 1px #c3d0fb; */
    text-align: center;
    min-width: 40px;
}


.close-button-container {
    background: lightgray;
    border: none;
    box-shadow: 1px 1px 6px grey;
    border-radius: 5px;
    padding: 4px 6px;
    cursor: pointer;
    position: fixed;
    right: 10px;
    bottom: 10px;
}

/* 검색 부분 */

.search-form {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.search-form input[type="text"] {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 20px 0 0 20px;
    outline: none;
    font-size: 14px;
    transition: all 0.3s ease;
}

.search-form input[type="text"]:focus {
    border-color: #aaa;
}

.search-form button {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-left: none;
    background-color: #007bff;
    color: white;
    border-radius: 0 20px 20px 0;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;
}

.search-form button:hover {
    background-color: #0056b3;
}

.header-tab-container .tab-position-guider {
    flex-grow: 1;
}